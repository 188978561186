.blue-bg {
  background-color: #00aaff; }

.pink-bg {
  background-color: #9B4594; }

.red-bg {
  background-color: #C63C00; }

.blue {
  color: #00aaff; }

.grey {
  color: #555555; }

.pink {
  color: #9B4594; }

.red {
  color: #C63C00; }

.white {
  color: #fff; }

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important; }

.site-template {
  max-width: 100vw;
  overflow: hidden;
  padding-top: 80px; }
  @media (max-width: 991px) {
    .site-template {
      padding-top: 50px; } }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .container.sm-wide {
      padding: 0; } }

@media (max-width: 575px) {
  .container {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .container {
    width: 90%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 940px;
    max-width: 100%; } }

.shadow-box {
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14); }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 50px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

*,
*::before,
*::after {
  box-sizing: border-box; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  html .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  html .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.aligncenter, .center {
  text-align: center; }

.alignleft, .left {
  text-align: left; }

.alignright, .right {
  text-align: right; }

.justify-center {
  justify-content: center; }

a {
  background-color: transparent;
  text-decoration: unset;
  color: unset; }
  a:active, a:focus, a:hover, a:visited {
    color: unset;
    text-decoration: unset; }

a.person-name {
  color: #C63C00;
  text-decoration: none !important; }

a.developer-insights {
  color: #C63C00;
  text-decoration: none !important; }
  a.developer-insights:visited {
    color: #C63C00; }

a.underline {
  position: relative;
  text-decoration: underline; }

a.anchor {
  display: block;
  position: relative;
  top: -130px;
  visibility: hidden; }

a.anchor-wide {
  display: block;
  position: relative;
  top: -230px;
  visibility: hidden; }

body {
  font-family: "Avenir", sans-serif;
  line-height: 1.6;
  color: #333333;
  font-size: 16px; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: 0.1px;
  margin-top: 0;
  margin-bottom: .75em; }

h1 {
  font-size: 40px; }
  @media (max-width: 991px) {
    h1 {
      font-size: 36px; } }
  @media (max-width: 660px) {
    h1 {
      font-size: 28px; } }

h2 {
  font-size: 34px; }
  @media (max-width: 660px) {
    h2 {
      font-size: 24px; } }

h3 {
  font-size: 28px; }
  @media (max-width: 660px) {
    h3 {
      font-size: 22px; } }

h4 {
  font-size: 24px; }
  @media (max-width: 660px) {
    h4 {
      font-size: 22px; } }

h5 {
  font-size: 22px; }
  @media (max-width: 660px) {
    h5 {
      font-size: 20px; } }

h6 {
  font-size: 18px; }

p {
  margin-bottom: .75em; }

b, strong {
  font-weight: 900; }

cite, dfn, em, i {
  font-style: italic; }

address {
  margin: 0 0 1.5em; }

ol, ul {
  margin: 0;
  list-style-position: outside;
  list-style-type: disc;
  text-align: left; }
  ol li, ul li {
    color: #1A1A1A;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.07px;
    line-height: 1.4;
    margin: 0.5em 0; }
  ol li > *, ul li > * {
    vertical-align: text-top;
    text-align: left; }

ol {
  list-style-type: decimal; }

ol, ul {
  padding-left: 1.6em; }
  @media (max-width: 600px) {
    ol, ul {
      padding-left: 1.2em; } }

ul.center, ol.center {
  list-style-position: inside; }

blockquote {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
  max-width: 500px; }
  blockquote .content {
    color: #333333;
    font-family: "Avenir Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: 1.35;
    position: relative;
    padding-left: 25px; }
    blockquote .content strong {
      font-family: "Avenir Black Oblique"; }
    blockquote .content:first-child:before {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      min-height: 84px;
      width: 6px;
      border-radius: 3px;
      background-color: #C63C00; }
  blockquote .author {
    margin-top: 12px;
    padding-left: 25px;
    color: #333333;
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;
    display: block; }
  blockquote .btn-wrap {
    margin-top: 22px;
    padding-left: 25px; }
  blockquote.no-border .content {
    padding-left: 0; }
    blockquote.no-border .content:first-child:before {
      display: none; }
  blockquote.no-border .author {
    padding-left: 0; }
  blockquote.no-border .btn-wrap {
    padding-left: 0; }
  @media (max-width: 660px) {
    blockquote .content {
      font-size: 20px; } }

button, input, optgroup, select, textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0; }

button, select, input, textarea, label {
  text-transform: unset;
  border: none;
  outline: none;
  background: unset; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px; }

textarea {
  width: 100%;
  display: block;
  resize: vertical; }

[type=checkbox], [type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=datetime]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=range]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  color: inherit; }

a:active, a:focus, a:hover {
  color: inherit; }

input {
  appearance: none; }
  input[type=search] {
    appearance: auto;
    outline: none; }
  input::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden; }

/* Webkit autofill background fix */
html body input:-webkit-autofill, html body input:-webkit-autofill:hover, html body input:-webkit-autofill:focus, html body input:-webkit-autofill:active, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
  transition: background-color 500000s ease-in-out 0s !important; }

img {
  max-width: 100%;
  display: block;
  border: 0;
  outline: 0;
  border: none;
  height: auto; }
  img.shadow {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14); }

hr {
  margin: 0; }

form {
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
  padding: 36px 32px;
  background-color: #fff; }
  form a {
    position: relative;
    text-decoration: underline; }
  form .form-group {
    margin-bottom: 20px; }
    form .form-group .label {
      color: #1A1A1A;
      font-family: "Avenir", sans-serif;
      font-weight: 500;
      font-size: 16px; }
    form .form-group .input {
      margin-top: 4px;
      display: block;
      border: 1px solid #777777;
      height: 40px;
      width: 100%;
      background-color: #fff;
      padding-left: 12px;
      padding-right: 24px;
      font-size: 16px;
      color: #1A1A1A;
      font-family: "Avenir", sans-serif;
      font-weight: 500; }
    form .form-group .select-wrap {
      margin-top: 4px;
      position: relative;
      display: block;
      width: 100%; }
      form .form-group .select-wrap:after {
        content: "";
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 7px 0 7px;
        border-color: #666666 transparent transparent transparent; }
      form .form-group .select-wrap .select {
        border: 1px solid #777777;
        height: 40px;
        width: 100%;
        background-color: #fff;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 16px;
        color: #1A1A1A;
        font-family: "Avenir", sans-serif;
        font-weight: 500;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
        form .form-group .select-wrap .select::-ms-expand {
          display: none; }
  form .submit-group button {
    width: 100%;
    display: block;
    cursor: pointer; }
  form .form-copyright {
    font-size: 14px;
    font-family: "Avenir", sans-serif;
    font-weight: 400; }
    form .form-copyright a {
      display: inline-block;
      position: relative;
      transition: 0.3s all ease; }
      form .form-copyright a:after {
        display: block;
        content: "";
        width: 100%;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #1A1A1A;
        transition: 0.3s all ease; }
      form .form-copyright a:hover {
        color: #C63C00; }
        form .form-copyright a:hover:after {
          background-color: #C63C00; }
  @media (max-width: 375px) {
    form {
      padding: 35px 20px; } }
  @media (max-width: 345px) {
    form {
      padding: 35px 15px; } }

table {
  border-collapse: collapse;
  width: 100%; }

td, th {
  border: 1px solid #ddd;
  padding: 8px; }

tr:nth-child(even) {
  background-color: #f2f2f2; }

tr:hover {
  background-color: #ddd; }

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #00aaff;
  color: white; }

/* Old Browser */
.browsers__placeholder {
  font-family: "Helvetica", sans-serif;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-image: none !important;
  text-align: center;
  background-color: black !important;
  font-size: 20px;
  color: #fff;
  z-index: 100000000000000000000000000000000000;
  line-height: 1.6; }
  .browsers__placeholder p {
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 1.6;
    font-family: inherit; }
  .browsers__placeholder a {
    color: red; }

/* Navigation */
.nav {
  display: block;
  width: 100%;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.28);
  background: #fff;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; }
  .nav .container {
    height: 100%; }
  .nav .nav-flex {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between; }
  .nav .logo {
    display: flex;
    align-items: center; }
    .nav .logo img {
      object-fit: contain;
      max-width: 250px; }
  .nav .menu-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* Menu */ }
    .nav .menu-wrap .menu {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-right: 10px;
      /* Dropdown Menu */ }
      .nav .menu-wrap .menu li {
        position: relative;
        margin-right: 15px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        list-style-type: none; }
        .nav .menu-wrap .menu li:before {
          position: absolute;
          content: "";
          background-color: #103658;
          height: 4px;
          width: 0;
          border-radius: 2px;
          display: block;
          margin-top: 3px;
          bottom: -6px;
          transition: 0.3s all ease; }
        .nav .menu-wrap .menu li a {
          color: #333333;
          font-family: "Avenir", sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
          transition: 0.3s all ease; }
          .nav .menu-wrap .menu li a em {
            font-family: "Avenir Black Oblique";
            font-weight: bold; }
        .nav .menu-wrap .menu li:hover:before, .nav .menu-wrap .menu li.current:before {
          width: 30px; }
        .nav .menu-wrap .menu li:hover.toplevel > a, .nav .menu-wrap .menu li.current.toplevel > a {
          color: #103658; }
        .nav .menu-wrap .menu li:last-child:after {
          display: none; }
      .nav .menu-wrap .menu .toplevel:after {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 14px;
        width: 1px;
        border: 1px solid #979797; }
      .nav .menu-wrap .menu li:hover .dropdown > ul {
        display: block; }
      .nav .menu-wrap .menu .dropdown {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 30px;
        top: 0px;
        align-items: left;
        list-style-type: none; }
        .nav .menu-wrap .menu .dropdown ul {
          background-color: #fff;
          display: none; }
        .nav .menu-wrap .menu .dropdown a {
          margin-top: 5px;
          margin-bottom: 5px; }
        .nav .menu-wrap .menu .dropdown li:hover > a {
          color: #C63C00; }
  .nav .btn-call-to-action {
    height: 40px;
    line-height: 40px;
    width: 140px;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    background-color: #00aaff;
    text-align: center;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1px; }
    .nav .btn-call-to-action:hover, .nav .btn-call-to-action:active, .nav .btn-call-to-action:focus {
      text-decoration: underline; }
  .nav .nav-toggle {
    margin-top: 2px;
    width: 60px;
    height: 45px;
    position: relative;
    transform: rotate(0) scale(0.65) translateX(15px);
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none; }
    .nav .nav-toggle span {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 3px;
      width: 100%;
      background: #333333;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out; }
      .nav .nav-toggle span:nth-child(1) {
        top: 0;
        transform-origin: left center; }
      .nav .nav-toggle span:nth-child(2) {
        top: 18px;
        transform-origin: left center; }
      .nav .nav-toggle span:nth-child(3) {
        top: 36px;
        transform-origin: left center; }
    .nav .nav-toggle.active span:nth-child(1) {
      transform: rotate(45deg);
      top: -3px;
      left: 8px; }
    .nav .nav-toggle.active span:nth-child(2) {
      width: 0;
      opacity: 0; }
    .nav .nav-toggle.active span:nth-child(3) {
      transform: rotate(-45deg);
      top: 39px;
      left: 8px; }
  @media (max-width: 991px) {
    .nav {
      height: 65px; }
      .nav .menu-wrap {
        display: none; }
      .nav .nav-toggle {
        display: block; } }
  @media (max-width: 340px) {
    .nav .logo img {
      max-width: 180px; }
    .nav .nav-toggle {
      transform: rotate(0deg) scale(0.5) translateX(30px); } }

/* Mobile Navigation */
.mobile-nav {
  display: none; }
  @media (max-width: 991px) {
    .mobile-nav {
      z-index: 100;
      background-color: #F1F1F1;
      top: 65px;
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      flex-direction: column; }
      .mobile-nav .container {
        padding-top: 20px;
        padding-bottom: 20px; }
      .mobile-nav .menu {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; }
        .mobile-nav .menu li {
          margin-bottom: 24px;
          list-style-type: none; }
          .mobile-nav .menu li a {
            font-size: 18px;
            font-weight: 500;
            transition: 0.3s all ease; }
            .mobile-nav .menu li a em {
              font-family: "Avenir Black Oblique";
              font-weight: bold; }
          .mobile-nav .menu li:hover a {
            color: #C63C00; }
          .mobile-nav .menu li.current a {
            color: #C63C00; }
      .mobile-nav .btn-call-to-action {
        display: block;
        height: 40px;
        line-height: 40px;
        width: 140px;
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
        background-color: #00aaff;
        text-align: center;
        font-family: "Avenir", sans-serif;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 1px; }
        .mobile-nav .btn-call-to-action:hover, .mobile-nav .btn-call-to-action:active, .mobile-nav .btn-call-to-action:focus {
          text-decoration: underline; } }
  @media (max-width: 340px) {
    .mobile-nav .container {
      padding-top: 30px;
      padding-bottom: 30px; }
    .mobile-nav .menu li {
      margin-bottom: 20px; }
      .mobile-nav .menu li a {
        font-size: 16px; } }

.footer {
  padding-top: 20px;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 38px;
  background-color: #444444;
  color: #fff;
  font-family: "Avenir", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6; }
  .footer .footer-top .col-left .footer-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .footer .footer-top .col-left .footer-links a {
      display: block;
      position: relative;
      margin-right: 15px;
      padding-right: 15px;
      text-decoration: underline; }
      .footer .footer-top .col-left .footer-links a:after {
        content: "";
        position: absolute;
        display: block;
        height: 10px;
        width: 1px;
        background-color: #fff;
        top: 50%;
        transform: translateY(-50%);
        right: 0; }
      .footer .footer-top .col-left .footer-links a:first-child {
        margin-left: 0; }
      .footer .footer-top .col-left .footer-links a:last-child:after {
        display: none; }
  .footer .footer-top .col-right {
    display: flex;
    justify-content: flex-end; }
    .footer .footer-top .col-right .email-link a {
      position: relative;
      text-decoration: underline; }
  .footer .footer-copyright {
    margin-top: 10px; }
  @media (max-width: 767px) {
    .footer .footer-top .col-right {
      margin-top: 10px;
      justify-content: flex-start; }
    .footer .footer-copyright {
      margin-top: 10px; } }

section.section-rounded {
  position: relative;
  padding-top: 40px;
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-rounded {
      margin-bottom: 60px; } }
  section.section-rounded:before {
    border-bottom-left-radius: 50% 100%;
    border-bottom-right-radius: 50% 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%);
    transform: rotate(180deg);
    height: 200px;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: block;
    content: "";
    position: absolute;
    z-index: -1;
    pointer-events: none; }
  section.section-rounded .container {
    position: relative;
    z-index: 1; }
  @media (max-width: 991px) {
    section.section-rounded:before {
      border-bottom-left-radius: 50% 20%;
      border-bottom-right-radius: 50% 20%; } }

.section-heading-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .section-heading-wrap .number-in-circle {
    margin-bottom: 15px; }
  .section-heading-wrap .section-heading {
    font-family: "Nunito", sans-serif;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.1px;
    text-align: center; }
  .section-heading-wrap hr {
    width: 200px;
    height: 3px;
    display: block;
    margin-top: 15px;
    margin-bottom: 0; }

.section-icons {
  margin-top: 55px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .section-icons .col-item {
    padding: 0 20px;
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: center; }
    .section-icons .col-item .icon-wrap {
      height: 55px;
      width: 55px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .section-icons .col-item .icon-wrap img {
        object-fit: contain; }
    .section-icons .col-item .caption {
      margin: 0;
      text-align: center;
      color: #555555; }
  @media (max-width: 767px) {
    .section-icons .col-item {
      margin-bottom: 35px;
      width: calc(100% / 2); } }
  @media (max-width: 565px) {
    .section-icons .col-item {
      margin-bottom: 50px;
      width: 100%; } }

section.section-center-aligned {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-center-aligned {
      margin-bottom: 60px; } }
  section.section-center-aligned .row {
    justify-content: center;
    margin-right: 0;
    margin-left: 0; }
  section.section-center-aligned .title {
    text-align: center; }
  section.section-center-aligned .sub-title {
    margin-top: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 420px; }
  section.section-center-aligned img {
    margin-top: 20px; }
  section.section-center-aligned .sub-content {
    margin-top: 45px;
    width: 100%;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto; }
  @media (max-width: 991px) {
    section.section-center-aligned {
      margin-top: 35px; } }

section.section-shield-with-shadow {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-shield-with-shadow {
      margin-bottom: 60px; } }
  section.section-shield-with-shadow .container {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
    padding: 30px 80px; }
    section.section-shield-with-shadow .container .section-sub-content {
      margin-top: 12px;
      max-width: 560px; }
    @media (max-width: 991px) {
      section.section-shield-with-shadow .container {
        padding: 40px 20px; } }
    @media (max-width: 660px) {
      section.section-shield-with-shadow .container {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
        text-align: left;
        max-width: calc(100% - 30px); } }

section.section-default {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-default {
      margin-bottom: 60px; } }

section.section-indented-text {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-indented-text {
      margin-bottom: 60px; } }
  section.section-indented-text .container {
    padding-left: 80px; }
    @media (max-width: 600px) {
      section.section-indented-text .container {
        padding-left: 40px; } }
    @media (max-width: 400px) {
      section.section-indented-text .container {
        padding-left: 20px; } }

/* Header */
.main-header {
  height: 830px;
  width: 100%;
  background-image: url(../img/site/bg_main.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    .main-header {
      margin-bottom: 60px; } }
  .main-header .header-content {
    width: 100%;
    padding-top: 40px; }
    .main-header .header-content .header-title {
      letter-spacing: 0.75px; }
    .main-header .header-content h2 {
      margin-bottom: 70px; }
  .main-header .partners {
    margin-top: 170px;
    align-items: center; }
    .main-header .partners__caption {
      letter-spacing: 1px; }
    .main-header .partners .partners__list {
      margin-top: 25px;
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .main-header .partners .partners__list .partner {
        display: flex;
        align-items: center;
        margin-right: 3%;
        height: 50px; }
        .main-header .partners .partners__list .partner img {
          display: block;
          object-fit: contain;
          height: 100%; }
        .main-header .partners .partners__list .partner:nth-child(1) img {
          max-height: 40px; }
        .main-header .partners .partners__list .partner:nth-child(2) img {
          max-height: 40px; }
        .main-header .partners .partners__list .partner:nth-child(3) img {
          max-height: 24px; }
        .main-header .partners .partners__list .partner:nth-child(4) img {
          max-height: 30px; }
        .main-header .partners .partners__list .partner:nth-child(5) img {
          max-height: 30px; }
        .main-header .partners .partners__list .partner:nth-child(6) img {
          max-height: 35px; }
  @media (max-width: 1199px) {
    .main-header {
      display: flex;
      align-items: center; }
      .main-header .header-content {
        padding: 0;
        padding-bottom: 50px; } }
  @media (max-width: 991px) {
    .main-header {
      height: auto;
      padding-top: 10px;
      padding-bottom: 75px; }
      .main-header .header-content {
        padding: 0; }
        .main-header .header-content .col-right {
          justify-content: flex-start;
          padding-top: 10px; }
          .main-header .header-content .col-right .console-wrap {
            max-width: 420px; }
      .main-header .partners .partners__list .partner {
        margin-bottom: 15px;
        height: 35px; }
        .main-header .partners .partners__list .partner:nth-child(1) img {
          max-height: 32.5px; }
        .main-header .partners .partners__list .partner:nth-child(2) img {
          max-height: 32.5px; }
        .main-header .partners .partners__list .partner:nth-child(3) img {
          max-height: 14px; }
        .main-header .partners .partners__list .partner:nth-child(4) img {
          max-height: 28px; }
        .main-header .partners .partners__list .partner:nth-child(5) img {
          max-height: 23px; }
        .main-header .partners .partners__list .partner:nth-child(6) img {
          max-height: 34px; } }
  @media (max-width: 767px) {
    .main-header {
      background-image: url(../img/site/bg_main_mobile.png);
      background-size: cover;
      background-position: bottom center;
      padding-top: 35px; }
      .main-header .partners__caption {
        text-align: center;
        margin-bottom: 15px; }
      .main-header .partners .partners__list {
        flex-direction: column; }
        .main-header .partners .partners__list .partner {
          margin: 12px auto !important; } }
  @media (max-width: 340px) {
    .main-header {
      margin-top: 65px; } }

.header-colored-hero {
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    .header-colored-hero {
      margin-bottom: 60px; } }
  .header-colored-hero .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    z-index: 1; }
  .header-colored-hero .overlay {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    opacity: 0.75; }
    .header-colored-hero .overlay.red {
      background-color: #C63C00; }
    .header-colored-hero .overlay.blue {
      background-color: #00aaff; }
    .header-colored-hero .overlay.pink {
      background-color: #9B4594; }
    .header-colored-hero .overlay.dark {
      background-color: #1A1A1A; }
  .header-colored-hero .container {
    position: relative;
    z-index: 10; }
  .header-colored-hero h1, .header-colored-hero h2, .header-colored-hero h3, .header-colored-hero h4, .header-colored-hero h5, .header-colored-hero h6, .header-colored-hero p {
    color: #fff; }
  .header-colored-hero strong {
    letter-spacing: 0.05em; }

.header-text-based {
  padding-top: 30px;
  position: relative;
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    .header-text-based {
      margin-bottom: 60px; } }

.image-wrap-logo {
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center; }
  .image-wrap-logo img {
    display: block;
    object-fit: contain;
    max-width: 200px; }

section.section-partners-companies {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-partners-companies {
      margin-bottom: 60px; } }
  section.section-partners-companies .partners-companies-wrapper {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
    padding-top: 40px;
    padding-bottom: 40px; }
  section.section-partners-companies .caption {
    text-align: center; }
  section.section-partners-companies .partners__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    section.section-partners-companies .partners__list .partner {
      display: flex;
      align-items: center;
      margin-right: 25px;
      height: 50px; }
      section.section-partners-companies .partners__list .partner img {
        display: block;
        object-fit: contain;
        height: 100%; }
      section.section-partners-companies .partners__list .partner:nth-child(1) img {
        max-height: 30px; }
      section.section-partners-companies .partners__list .partner:nth-child(2) img {
        max-height: 44.5px; }
      section.section-partners-companies .partners__list .partner:nth-child(3) img {
        max-height: 21px; }
      section.section-partners-companies .partners__list .partner:nth-child(4) img {
        max-height: 35px; }
      section.section-partners-companies .partners__list .partner:nth-child(5) img {
        max-height: 35px; }
      section.section-partners-companies .partners__list .partner:nth-child(6) img {
        max-height: 46px; }
  @media (max-width: 991px) {
    section.section-partners-companies .partners .partners__list .partner {
      height: 35px; }
      section.section-partners-companies .partners .partners__list .partner:nth-child(1) img {
        max-height: 32.5px; }
      section.section-partners-companies .partners .partners__list .partner:nth-child(2) img {
        max-height: 32.5px; }
      section.section-partners-companies .partners .partners__list .partner:nth-child(3) img {
        max-height: 14px; }
      section.section-partners-companies .partners .partners__list .partner:nth-child(4) img {
        max-height: 28px; }
      section.section-partners-companies .partners .partners__list .partner:nth-child(5) img {
        max-height: 23px; }
      section.section-partners-companies .partners .partners__list .partner:nth-child(6) img {
        max-height: 34px; } }

section.section-case-study-cards {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-case-study-cards {
      margin-bottom: 60px; } }
  section.section-case-study-cards .title {
    text-align: center; }
  section.section-case-study-cards .sub-title {
    margin-top: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 420px; }
  section.section-case-study-cards .case-study-card {
    position: relative;
    padding: 20px 40px 5px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14); }
    section.section-case-study-cards .case-study-card .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover; }
    section.section-case-study-cards .case-study-card .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.75; }
      section.section-case-study-cards .case-study-card .overlay.red {
        background-color: #C63C00; }
      section.section-case-study-cards .case-study-card .overlay.blue {
        background-color: #00aaff; }
      section.section-case-study-cards .case-study-card .overlay.pink {
        background-color: #9B4594; }
      section.section-case-study-cards .case-study-card .overlay.dark {
        background-color: #1A1A1A; }
    section.section-case-study-cards .case-study-card .case-study-inner {
      color: #fff;
      position: relative;
      z-index: 10; }
    section.section-case-study-cards .case-study-card .case-study-partner-image {
      display: block;
      margin-bottom: 20px; }
      section.section-case-study-cards .case-study-card .case-study-partner-image img {
        display: block;
        object-fit: contain;
        max-height: 32px; }
      section.section-case-study-cards .case-study-card .case-study-partner-image.small img {
        max-height: 28px; }
    section.section-case-study-cards .case-study-card .case-study-title {
      color: #fff;
      font-family: "Nunito", sans-serif;
      font-weight: 400;
      line-height: 1.35;
      display: block;
      margin-bottom: 10px; }
    section.section-case-study-cards .case-study-card .case-study-content .review {
      color: #fff;
      font-family: "Nunito", sans-serif; }
      section.section-case-study-cards .case-study-card .case-study-content .review p {
        font-size: 22px;
        font-style: oblique;
        font-weight: 600;
        line-height: 1.35;
        letter-spacing: 0.03em; }
        section.section-case-study-cards .case-study-card .case-study-content .review p:last-child {
          margin-bottom: 0; }
    section.section-case-study-cards .case-study-card .case-study-content .author {
      text-transform: uppercase;
      color: #fff;
      font-family: "Avenir", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 5px;
      margin-bottom: 10px; }
    section.section-case-study-cards .case-study-card .cta-btn {
      margin-top: 0;
      margin-bottom: 20px; }
    @media (max-width: 565px) {
      section.section-case-study-cards .case-study-card {
        padding-left: 35px;
        padding-right: 35px; }
        section.section-case-study-cards .case-study-card .case-study-content .review {
          font-size: 18px; } }
    @media (max-width: 360px) {
      section.section-case-study-cards .case-study-card {
        padding-left: 15px;
        padding-right: 15px; } }

.ratings-table {
  margin: 40px 0;
  padding: 35px 40px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
  display: block; }
  .ratings-table__title {
    color: #181818;
    font-family: "Avenir", sans-serif;
    font-size: 22px;
    font-weight: 900;
    line-height: 1.35;
    display: block;
    margin-bottom: 18px; }
  .ratings-table__row {
    height: 40px;
    display: flex;
    align-items: center; }
    .ratings-table__row .title {
      margin: 0;
      width: 45%;
      display: flex;
      align-items: center;
      height: 40px;
      position: relative;
      border-right: 1px solid #979797;
      padding-right: 15px; }
      .ratings-table__row .title .label {
        padding-right: 15px;
        color: #181818;
        font-family: "Avenir", sans-serif;
        font-size: 18px;
        font-weight: 500; }
      .ratings-table__row .title .icon {
        right: 15px;
        position: absolute;
        top: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        display: none; }
        .ratings-table__row .title .icon svg {
          width: 25px;
          height: 25px;
          display: block;
          object-fit: contain; }
          .ratings-table__row .title .icon svg path {
            fill: #24B91C; }
        .ratings-table__row .title .icon.active {
          display: flex; }
    .ratings-table__row .status {
      height: 40px;
      width: 20%;
      border-right: 1px solid #979797;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      padding-left: 25px;
      padding-right: 25px;
      color: #181818;
      font-family: "Avenir", sans-serif;
      font-size: 16px;
      font-weight: 300;
      text-transform: capitalize; }
    .ratings-table__row .rating {
      height: 40px;
      width: 35%;
      display: flex;
      align-items: center; }
      .ratings-table__row .rating > span {
        display: block;
        height: 30px;
        margin: 0 1.5px;
        width: calc(100% / 4 - 3px); }
        .ratings-table__row .rating > span.red {
          background-color: #C63C00; }
        .ratings-table__row .rating > span.blue {
          background-color: #a2b907; }
        .ratings-table__row .rating > span.green {
          background-color: #24B91C; }
        .ratings-table__row .rating > span.orange {
          background-color: #CEB11C; }
        .ratings-table__row .rating > span:first-child {
          margin-left: 3px; }
        .ratings-table__row .rating > span:last-child {
          margin-right: 3px; }
  @media (max-width: 768px) {
    .ratings-table {
      padding: 25px 15px; }
      .ratings-table__row {
        flex-wrap: wrap;
        height: auto;
        margin-bottom: 15px; }
        .ratings-table__row .title {
          border: 0;
          width: 100%;
          display: flex;
          align-items: center; }
          .ratings-table__row .title .label {
            font-weight: 600; }
          .ratings-table__row .title .icon {
            position: static; }
        .ratings-table__row .status {
          width: 35%;
          padding-left: 0; }
        .ratings-table__row .rating {
          width: 65%; } }

.process-graph {
  margin: 40px 0;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
  padding: 10px; }
  .process-graph h6 {
    text-align: right;
    font-style: italic; }
  .process-graph .graph-wrap {
    display: flex;
    margin-left: -4px;
    margin-right: -4px;
    padding: 60px 10px 100px; }
  .process-graph .part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 4px; }
    .process-graph .part .caption {
      color: #1A1A1A;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4;
      font-family: "Nunito", sans-serif;
      bottom: 0;
      position: absolute;
      display: flex;
      align-items: center;
      transform: translateY(calc(100% + 30px));
      min-width: 200px;
      max-width: 300px;
      width: auto;
      left: 0;
      white-space: pre-line; }
    .process-graph .part .triangle {
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 15px 0 15px;
      border-color: transparent transparent transparent transparent;
      position: absolute;
      bottom: 0;
      transform: translateY(100%); }
    .process-graph .part .graph-part {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px; }
      .process-graph .part .graph-part .label {
        text-align: center;
        font-family: "Avenir", sans-serif;
        color: #1A1A1A;
        font-weight: 900;
        font-size: 15px;
        max-width: 90%;
        line-height: 1.4; }
    .process-graph .part.top {
      flex-direction: column-reverse; }
      .process-graph .part.top .triangle {
        top: 0;
        bottom: auto;
        transform: translateY(-100%) rotate(180deg); }
      .process-graph .part.top .caption {
        left: auto;
        right: auto;
        bottom: auto;
        top: 0;
        transform: translateY(calc(-100% - 30px)); }
    .process-graph .part.red .graph-part {
      border: 1px solid #E4787C;
      background-color: #F4CCCC; }
    .process-graph .part.red .triangle {
      border-color: #FF0000 transparent transparent transparent; }
    .process-graph .part.orange .graph-part {
      background-color: #FDE5CE;
      border: 1px solid #EBA660; }
    .process-graph .part.orange .triangle {
      border-color: #FF9A00 transparent transparent transparent; }
    .process-graph .part.blue .graph-part {
      background-color: #C9DAF8;
      border: 1px solid #3D79D8; }
    .process-graph .part.blue .triangle {
      border-color: #6D9EEC transparent transparent transparent; }
    .process-graph .part.green .graph-part {
      background-color: #DAEAD3;
      border: 1px solid #5D904A; }
    .process-graph .part.green .triangle {
      border-color: #5D904A transparent transparent transparent; }
    .process-graph .part.logo .triangle {
      border: 0;
      background-image: url(../img/components/process-graph/logo-triangle.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      height: 22px;
      width: 22px; }
    .process-graph .part.logo.top .triangle {
      transform: translateY(-100%); }
  .process-graph .part-1 {
    width: calc(22% - 8px); }
    .process-graph .part-1 .graph-part {
      border-radius: 35px 0 0 35px; }
  .process-graph .part-2 {
    width: calc(38% - 8px); }
  .process-graph .part-3 {
    width: calc(18% - 8px); }
  .process-graph .part-4 {
    width: calc(22% - 8px); }
    .process-graph .part-4 .graph-part {
      border-radius: 0 35px 35px 0; }
  @media (max-width: 991px) {
    .process-graph .graph-wrap {
      padding: 20px 0;
      flex-direction: column;
      margin: 0; }
      .process-graph .graph-wrap .part {
        margin-bottom: 35px;
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
        flex-direction: column !important; }
        .process-graph .graph-wrap .part:last-child {
          margin-bottom: 0; }
        .process-graph .graph-wrap .part .graph-part {
          border-radius: 0;
          height: 60px; }
          .process-graph .graph-wrap .part .graph-part .label {
            font-size: 16px; }
        .process-graph .graph-wrap .part .triangle {
          transform: none;
          position: static; }
        .process-graph .graph-wrap .part.logo .triangle {
          transform: rotate(180deg); }
        .process-graph .graph-wrap .part .caption {
          margin-top: 5px;
          position: static;
          transform: none;
          text-align: center; } }

.btn {
  display: inline-block;
  width: 220px;
  color: #fff;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  border-radius: 6px;
  padding: 0 22px;
  transition: 0.3s all ease; }
  .btn.btn-dark {
    box-sizing: border-box;
    line-height: 42px;
    width: auto;
    border: 1px solid #fff;
    border-radius: 6px;
    background-color: #333333;
    color: #fff;
    letter-spacing: 1px; }
  .btn.btn-dark-empty {
    padding: 8px 22px;
    box-sizing: border-box;
    line-height: 24px;
    width: auto;
    border-radius: 6px;
    letter-spacing: 1px;
    background-color: transparent;
    color: #333333;
    border: 2px solid #333333; }
    .btn.btn-dark-empty:hover, .btn.btn-dark-empty:active, .btn.btn-dark-empty:focus {
      background-color: #333333;
      color: #fff; }
  .btn.btn-white-empty {
    box-sizing: border-box;
    line-height: 42px;
    width: auto;
    border-radius: 6px;
    letter-spacing: 1px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff; }
    .btn.btn-white-empty:hover, .btn.btn-white-empty:active, .btn.btn-white-empty:focus {
      background-color: #fff;
      color: #333333; }
  .btn.btn-red {
    background-color: #C63C00;
    box-sizing: border-box;
    line-height: 42px;
    width: auto;
    border: 2px solid #fff;
    border-radius: 6px;
    color: #fff;
    letter-spacing: 1px; }
    .btn.btn-red:hover, .btn.btn-red:active, .btn.btn-red:focus {
      text-decoration: underline; }
  .btn.btn-red-empty {
    box-sizing: border-box;
    line-height: 42px;
    width: auto;
    border-radius: 6px;
    letter-spacing: 1px;
    background-color: transparent;
    color: #C63C00;
    border: 2px solid #C63C00; }
    .btn.btn-red-empty:hover, .btn.btn-red-empty:active, .btn.btn-red-empty:focus {
      background-color: #C63C00;
      color: #fff; }
  .btn.btn-blue {
    background-color: #00aaff;
    box-sizing: border-box;
    line-height: 42px;
    width: auto;
    border: 2px solid #fff;
    border-radius: 6px;
    color: #fff;
    letter-spacing: 1px; }
    .btn.btn-blue:hover, .btn.btn-blue:active, .btn.btn-blue:focus {
      text-decoration: underline; }
  .btn.btn-blue-empty {
    box-sizing: border-box;
    line-height: 42px;
    width: auto;
    border-radius: 6px;
    letter-spacing: 1px;
    background-color: transparent;
    color: #C63C00;
    border: 2px solid #00aaff; }
    .btn.btn-blue-empty:hover, .btn.btn-blue-empty:active, .btn.btn-blue-empty:focus {
      background-color: #00aaff;
      color: #fff; }
  .btn.large {
    max-width: 100%;
    min-width: 256px; }
  @media (max-width: 340px) {
    .btn {
      font-size: 15px;
      padding: 0 15px; } }

.standalone-logo {
  margin-top: 15px;
  margin-bottom: 35px; }

.shareline-logo {
  margin-top: 15px;
  margin-left: 20px; }

.number-in-circle {
  width: 55px;
  min-width: 55px;
  height: 55px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 24px;
  font-weight: 800;
  text-align: center; }

section.section-trial {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-trial {
      margin-bottom: 60px; } }
  section.section-trial .content {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
    padding: 35px; }
    section.section-trial .content .heading {
      color: #C63C00;
      display: block;
      margin-bottom: 12px; }
    section.section-trial .content .sub-heading {
      color: #1A1A1A;
      font-family: "Avenir", sans-serif;
      font-weight: 500;
      letter-spacing: 0.09px;
      line-height: 1.35; }
    section.section-trial .content ul li {
      font-size: 18px; }
    section.section-trial .content .btn-wrap {
      margin-top: 25px; }

section.section-book-call {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-book-call {
      margin-bottom: 60px; } }
  section.section-book-call .content {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
    padding: 35px; }
    section.section-book-call .content .heading {
      color: #103658;
      display: block;
      margin-bottom: 12px; }
    section.section-book-call .content .sub-heading {
      color: #1A1A1A;
      font-family: "Avenir", sans-serif;
      font-weight: 500;
      letter-spacing: 0.09px;
      line-height: 1.35; }
    section.section-book-call .content ul li {
      font-size: 18px; }
    section.section-book-call .content .btn-wrap {
      margin-top: 25px; }

.list-with-round-numbers {
  margin-top: 10px;
  margin-bottom: 20px; }
  .list-with-round-numbers .list-item {
    margin-bottom: 32px;
    display: flex;
    align-items: center; }
    .list-with-round-numbers .list-item:last-child {
      margin-bottom: 0; }
    .list-with-round-numbers .list-item .icon {
      display: flex;
      align-items: center;
      margin-right: 25px;
      min-width: 40px;
      width: 40px;
      justify-content: center; }
      .list-with-round-numbers .list-item .icon img {
        object-fit: contain;
        min-height: 30px;
        display: block; }
    .list-with-round-numbers .list-item .label {
      color: #555555;
      font-family: "Avenir", sans-serif;
      font-size: 16px;
      font-weight: 500; }

ul.list-with-right-arrows {
  list-style: none;
  padding-left: 0.4em; }
  ul.list-with-right-arrows li {
    margin-left: 1.3rem;
    position: relative;
    font-size: 18px;
    color: #fff; }
    @media (max-width: 660px) {
      ul.list-with-right-arrows li {
        font-size: 16px; } }
  ul.list-with-right-arrows li:before {
    content: " ";
    background-size: cover;
    background-image: url("../img/components/list/right_sign_white.svg");
    position: absolute;
    width: 1em;
    height: 1em;
    top: 0.2em;
    left: -1.5em; }

ul.list-table-of-contents {
  padding-left: 0.4em;
  text-align: left; }
  ul.list-table-of-contents li.level1 {
    list-style: none;
    margin-left: 1.5em;
    padding-left: 0.3em;
    position: relative;
    font-size: 16px;
    color: #555555; }
    @media (max-width: 660px) {
      ul.list-table-of-contents li.level1 {
        font-size: 18px; } }
  ul.list-table-of-contents li.level1:before {
    content: " ";
    background-size: cover;
    background-image: url("../img/components/list/right_sign_blue.svg");
    position: absolute;
    width: 1.2em;
    height: 1.2em;
    top: 0.1em;
    left: -1.6em; }
  ul.list-table-of-contents li.level2 {
    list-style: outside;
    margin-left: 3rem;
    padding-left: 0.3em;
    position: relative;
    font-size: 14px;
    color: #00aaff; }
    @media (max-width: 660px) {
      ul.list-table-of-contents li.level2 {
        font-size: 16px; } }
  ul.list-table-of-contents a {
    display: inline-block;
    position: relative;
    transition: 0.3s all ease;
    color: #1A1A1A; }
    ul.list-table-of-contents a:hover {
      color: #00aaff;
      text-decoration: underline; }

ul.list-header-white li {
  color: #fff;
  line-height: 1.2; }

.img-wrap-with-shadow {
  display: inline-block;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14); }
  .img-wrap-with-shadow img {
    display: block;
    object-fit: contain; }

.questions {
  display: flex;
  flex-direction: column; }
  .questions .row {
    margin-top: 15px; }

.question-item {
  display: flex;
  flex-direction: row; }
  .question-item p {
    vertical-align: middle;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.18;
    letter-spacing: 0.1px;
    margin-left: 10px; }

.lang-icons-grid {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .lang-icons-grid .lang-logo-col {
    width: calc(100% / 3);
    position: relative; }
    .lang-icons-grid .lang-logo-col:after {
      content: "";
      position: absolute;
      right: 0;
      height: 65px;
      width: 1px;
      display: block;
      background-color: #979797;
      top: 45px; }
    .lang-icons-grid .lang-logo-col:last-child:after {
      display: none; }
    .lang-icons-grid .lang-logo-col .part-title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 16px;
      color: #333333;
      font-family: "Avenir", sans-serif;
      font-weight: bold;
      margin-bottom: 20px; }
    .lang-icons-grid .lang-logo-col .lang-logo-col-flex {
      display: flex;
      justify-content: space-between; }
      .lang-icons-grid .lang-logo-col .lang-logo-col-flex .lang-item {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .lang-icons-grid .lang-logo-col .lang-logo-col-flex .lang-item .img-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px; }
          .lang-icons-grid .lang-logo-col .lang-logo-col-flex .lang-item .img-wrap img {
            max-width: 100%;
            object-fit: contain; }
        .lang-icons-grid .lang-logo-col .lang-logo-col-flex .lang-item .lang-title {
          margin-top: 12px;
          text-align: center;
          font-size: 12px;
          color: #333333;
          font-weight: bold;
          font-family: "Avenir", sans-serif; }
    .lang-icons-grid .lang-logo-col:nth-child(1) {
      padding-right: 40px; }
    .lang-icons-grid .lang-logo-col:nth-child(2) {
      padding: 0 40px; }
      .lang-icons-grid .lang-logo-col:nth-child(2) .lang-logo-col-flex {
        justify-content: space-around; }
    .lang-icons-grid .lang-logo-col:nth-child(3) {
      padding-left: 40px; }
  @media (max-width: 767px) {
    .lang-icons-grid {
      justify-content: center; }
      .lang-icons-grid .lang-logo-col {
        padding: 0 40px !important;
        width: calc(100% / 2);
        margin-bottom: 50px; }
        .lang-icons-grid .lang-logo-col:after {
          display: none; } }
  @media (max-width: 565px) {
    .lang-icons-grid {
      justify-content: center; }
      .lang-icons-grid .lang-logo-col {
        width: 100%;
        margin-bottom: 45px; }
        .lang-icons-grid .lang-logo-col .lang-logo-col-flex {
          justify-content: center !important; }
          .lang-icons-grid .lang-logo-col .lang-logo-col-flex .lang-item {
            padding: 0 10px; } }

.report-image-with-overlay {
  max-width: calc(100% - 165px);
  margin: 30px auto 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .report-image-with-overlay img {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
    display: block;
    object-fit: contain; }
  .report-image-with-overlay .report-overlay-text {
    padding: 23px;
    width: 360px;
    background-color: #fff;
    position: absolute;
    margin: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-80px) translateY(64px);
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14); }
    .report-image-with-overlay .report-overlay-text .section-heading {
      margin-bottom: 12px;
      display: block; }
    .report-image-with-overlay .report-overlay-text h4 {
      margin: 0; }
    .report-image-with-overlay .report-overlay-text .section-sub-content ul {
      list-style-type: disc;
      padding-top: 10px;
      padding-left: 0.5em;
      list-style-position: inside; }
      .report-image-with-overlay .report-overlay-text .section-sub-content ul li {
        line-height: 1.5;
        margin: 0; }
  @media (max-width: 991px) {
    .report-image-with-overlay {
      max-width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0; }
      .report-image-with-overlay .report-overlay-text {
        position: static;
        transform: none;
        width: 100%; }
        .report-image-with-overlay .report-overlay-text h4 {
          margin: 0; } }

.julian-and-nick-img {
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .julian-and-nick-img img {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
    display: block;
    object-fit: contain; }
  @media (max-width: 991px) {
    .julian-and-nick-img {
      max-width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0; } }

section.section-pricing-cards {
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    section.section-pricing-cards {
      margin-bottom: 60px; } }
  section.section-pricing-cards .pricing-card {
    position: relative;
    padding-top: 10px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 5px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14); }
    section.section-pricing-cards .pricing-card .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover; }
    section.section-pricing-cards .pricing-card .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.75; }
      section.section-pricing-cards .pricing-card .overlay.red {
        background-color: #C63C00; }
      section.section-pricing-cards .pricing-card .overlay.blue {
        background-color: #00aaff; }
      section.section-pricing-cards .pricing-card .overlay.pink {
        background-color: #9B4594; }
      section.section-pricing-cards .pricing-card .overlay.dark {
        background-color: #1A1A1A; }
    section.section-pricing-cards .pricing-card .pricing-content {
      color: #fff;
      font-family: "Nunito", sans-serif;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      z-index: 10; }
      section.section-pricing-cards .pricing-card .pricing-content .package-name {
        font-size: 22px;
        font-style: oblique;
        font-weight: 600;
        line-height: 1.35;
        letter-spacing: 0.03em; }
      section.section-pricing-cards .pricing-card .pricing-content .footnote {
        text-transform: lowercase;
        font-family: "Avenir", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 5px;
        margin-bottom: 20px; }
      section.section-pricing-cards .pricing-card .pricing-content .collapse:not(.show) {
        display: none; }
    section.section-pricing-cards .pricing-card .cta-btn {
      margin-top: 0;
      margin-bottom: 20px; }
    @media (max-width: 565px) {
      section.section-pricing-cards .pricing-card {
        padding-left: 35px;
        padding-right: 35px; }
        section.section-pricing-cards .pricing-card .pricing-content .review {
          font-size: 18px; } }
    @media (max-width: 360px) {
      section.section-pricing-cards .pricing-card {
        padding-left: 15px;
        padding-right: 15px; } }
