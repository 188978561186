ul.list-with-right-arrows {
  list-style: none;
  padding-left: 0.4em;

  li {
    margin-left: 1.3rem;
    position: relative;
    font-size: 18px;
    color: $color-white;

    @media (max-width: 660px) {
      font-size: 16px;
    }
  }

  li:before {
    content: " ";
    background-size: cover;
    background-image: url("../img/components/list/right_sign_white.svg");
    position: absolute;
    width: 1em;
    height: 1em;
    top: 0.2em;
    left: -1.5em;
  }
}