.number-in-circle {
  width: 55px;
  min-width: 55px;
  height: 55px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  font-family: $font-nunito;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
}
