
button, input, optgroup, select, textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0
}


button, select, input, textarea, label {
  text-transform: unset;
  border: none;
  outline: none;
  background: unset;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px
}

textarea {
  width: 100%;
  display: block;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}


input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=datetime]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=range]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  color: inherit;
}

a:active, a:focus, a:hover {
  color: inherit
}

input {
  appearance: none;

  &[type=search] {
    appearance: auto;
    outline: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }
}

/* Webkit autofill background fix */
html body input, textarea {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 500000s ease-in-out 0s!important;
  }
}