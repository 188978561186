a.anchor {
  display: block;
  position: relative;
  top: -130px;
  visibility: hidden;
}

a.anchor-wide {
  display: block;
  position: relative;
  top: -230px;
  visibility: hidden;
}

