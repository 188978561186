section.section-case-study-cards {
  @include site-spacing-margin-bottom;

  .title {
    text-align: center;
  }

  .sub-title {
    margin-top: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 420px;
  }

  .case-study-card {
    position: relative;
    padding: 20px 40px 5px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);

    // Background
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }

    // Overlay
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.75;

      &.red {
        background-color: $color-red;
      }

      &.blue {
        background-color: $color-blue;
      }

      &.pink {
        background-color: $color-pink;
      }

      &.dark {
        background-color: $color-dark;
      }
    }

    // Inner Content
    .case-study-inner {
      color: $color-white;
      position: relative;
      z-index: 10;
    }

    // Partner Logo
    .case-study-partner-image {
      display: block;
      margin-bottom: 20px;

      img {
        display: block;
        object-fit: contain;
        max-height: 32px;
      }

      &.small {
        img {
          max-height: 28px;
        }
      }
    }

    // Title
    .case-study-title {
      color: $color-white;
      font-family: $font-nunito;
      font-weight: 400;
      line-height: 1.35;
      display: block;
      margin-bottom: 10px;
    }

    // Content
    .case-study-content {
      .review {
        color: $color-white;
        font-family: $font-nunito;

        p {
          font-size: 22px;
          font-style: oblique;
          font-weight: 600;
          line-height: 1.35;
          letter-spacing: 0.03em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .author {
        text-transform: uppercase;
        color: $color-white;
        font-family: $font-avenir;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }

    // Cta Button
    .cta-btn {
      margin-top: 0;
      margin-bottom: 20px;
    }

    @media (max-width: 565px) {
      padding-left: 35px;
      padding-right: 35px;
      .case-study-content .review {
        font-size: 18px;
      }
    }
    @media (max-width: 360px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}