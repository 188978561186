

.aligncenter, .center {
  text-align: center;
}

.alignleft, .left {
  text-align: left;
}

.alignright, .right {
  text-align: right;
}

.justify-center{
  justify-content: center;
}


