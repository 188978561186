section.section-pricing-cards {
  @include site-spacing-margin-bottom;

  .pricing-card {
    position: relative;
    padding-top: 10px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 5px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);

    // Background
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }

    // Overlay
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.75;

      &.red {
        background-color: $color-red;
      }

      &.blue {
        background-color: $color-blue;
      }

      &.pink {
        background-color: $color-pink;
      }

      &.dark {
        background-color: $color-dark;
      }
    }

    // Inner Content
    .pricing-content {
      color: $color-white;
      font-family: $font-nunito;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      z-index: 10;

      // Content
      .package-name {
          font-size: 22px;
          font-style: oblique;
          font-weight: 600;
          line-height: 1.35;
          letter-spacing: 0.03em;
      }

      .footnote {
        text-transform: lowercase;
        font-family: $font-avenir;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 5px;
        margin-bottom: 20px;
      }

      .collapse {
        &:not(.show) {
          display: none;
        }
      }
    }

    // Cta Button
    .cta-btn {
      margin-top: 0;
      margin-bottom: 20px;
    }

    @media (max-width: 565px) {
      padding-left: 35px;
      padding-right: 35px;
      .pricing-content .review {
        font-size: 18px;
      }
    }
    @media (max-width: 360px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}