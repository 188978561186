section.section-center-aligned {
  @include site-spacing-margin-bottom;

  .row {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }

  .title {
    text-align: center;
  }

  .sub-title {
    margin-top: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 420px;
  }

  img {
    margin-top: 20px;
  }

  .sub-content {
    margin-top: 45px;
    width: 100%;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 991px) {
    margin-top: 35px;
  }
}