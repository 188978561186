blockquote {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
  max-width: 500px;

  .content {
    color: $color-text;
    font-family: $font-avenor-medium-obl;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.35;
    position: relative;
    padding-left: 25px;

    strong {
      font-family: $font-avenir-black-obl;
    }

    &:first-child {
      &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        min-height: 84px;
        width: 6px;
        border-radius: 3px;
        background-color: $color-red;
      }
    }
  }

  .author {
    margin-top: 12px;
    padding-left: 25px;
    color: $color-text;
    font-family: $font-avenir;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;
    display: block;
  }

  .btn-wrap {
    margin-top: 22px;
    padding-left: 25px;
  }


  // Simple Style
  &.no-border {
    .content {
      padding-left: 0;

      &:first-child {
        &:before {
          display: none;
        }
      }
    }

    .author {
      padding-left: 0;
    }

    .btn-wrap {
      padding-left: 0;
    }
  }


  @media (max-width: 660px) {
    .content {
      font-size: 20px;
    }
  }
}
