/* Navigation */
.nav {
  display: block;
  width: 100%;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.28);
  background: $color-white;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .container {
    height: 100%;
  }

  .nav-flex {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  // Logo
  .logo {
    display: flex;
    align-items: center;

    img {
      object-fit: contain;
      max-width: 250px;
    }
  }

  // Menu Wrap
  .menu-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    /* Menu */
    .menu {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-right: 10px;

      li {
        position: relative;
        margin-right: 15px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        list-style-type: none;

        // The red line underneath the text
        &:before {
          position: absolute;
          content: "";
          background-color: $color-dark-blue;
          height: 4px;
          width: 0;
          border-radius: 2px;
          display: block;
          margin-top: 3px;
          bottom: -6px;
          @include transition;
        }

        a {
          color: $color-text;
          font-family: $font-avenir;
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
          @include transition;

          em {
            font-family: $font-avenir-black-obl;
            font-weight: bold;
          }
        }

        &:hover, &.current {
          &:before {
            width: 30px;
          }
        }

        &:hover, &.current  {
           &.toplevel > a {
            color: $color-dark-blue;
          }
        }

        &:last-child{
          &:after{
            display: none;
          }
        }
      }

      // The menu separator (a border)
      .toplevel:after {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 14px;
        width: 1px;
        border: 1px solid #979797;
      }

      /* Dropdown Menu */
      li:hover .dropdown > ul {
        display: block;
      }
      .dropdown {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 30px;
        top: 0px;
        align-items: left;
        list-style-type: none;

        ul {
          background-color: $color-white;
          display: none;
        }

        a {
          margin-top: 5px;
          margin-bottom: 5px;
        }

        li:hover > a {
          color: $color-red;
        }
      }
    }

  }

  // Call to action buttn
  .btn-call-to-action {
    height: 40px;
    line-height: 40px;
    width: 140px;
    color: $color-white;
    border-radius: 6px;
    cursor: pointer;
    background-color: $color-blue;
    text-align: center;
    font-family: $font-avenir;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1px;

    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }

  // Nav Toggle
  .nav-toggle {
    margin-top: 2px;
    width: 60px;
    height: 45px;
    position: relative;
    transform: rotate(0) scale(.65) translateX(15px);
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;

    span {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 3px;
      width: 100%;
      background: #333333;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 18px;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 36px;
        transform-origin: left center;
      }
    }

    &.active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: -3px;
          left: 8px;
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 39px;
          left: 8px;
        }

      }
    }
  }

  @media (max-width: 991px) {
    height: 65px;
    .menu-wrap {
      display: none;
    }
    .nav-toggle {
      display: block;
    }
  }
  @media (max-width: 340px) {
    .logo {
      img {
        max-width: 180px;
      }
    }

    .nav-toggle {
      transform: rotate(0deg) scale(0.5) translateX(30px);
    }

  }

}

/* Mobile Navigation */
.mobile-nav {
  display: none;
  @media (max-width: 991px) {
    z-index: 100;
    background-color: #F1F1F1;
    top: 65px;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    .container {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .menu {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      li {
        margin-bottom: 24px;
        list-style-type: none;


        a {
          font-size: 18px;
          font-weight: 500;
          @include transition;
          em{
            font-family: $font-avenir-black-obl;
            font-weight: bold;
          }
        }

        &:hover{
          a {
            color: $color-red;
          }
        }

        &.current{
          a{
            color: $color-red;
          }
        }

      }
    }
    .btn-call-to-action {
      display: block;
      height: 40px;
      line-height: 40px;
      width: 140px;
      color: $color-white;
      border-radius: 6px;
      cursor: pointer;
      background-color: $color-blue;
      text-align: center;
      font-family: $font-avenir;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 1px;

      &:hover, &:active, &:focus {
        text-decoration: underline;
      }
    }
  }
  @media (max-width: 340px) {
        .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .menu {
      li {
        margin-bottom: 20px;

        a {
          font-size: 16px;
        }
      }
    }
  }
}