
ol, ul {
  margin: 0;
  list-style-position: outside;
  list-style-type: disc;
  text-align: left;

  li {
    color: $color-dark;
    font-family: $font-avenir;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.07px;
    line-height: 1.4;
    margin: 0.5em 0;
  }

  li > * {
    vertical-align: text-top;
    text-align: left;
  }
}

ol {
  list-style-type: decimal;
}

ol, ul {
  padding-left: 1.6em;

  @media (max-width: 600px) {
    padding-left: 1.2em;
  }
}

ul.center, ol.center {
  list-style-position: inside;
}