
body {
  font-family: $font-avenir;
  line-height: 1.6;
  color: $color-text;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-nunito;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: 0.1px;
  margin-top: 0;
  margin-bottom: .75em;
}

h1 {
  font-size: 40px;

  @media (max-width: 991px) {
    font-size: 36px;
  }
  @media (max-width: 660px) {
    font-size: 28px;
  }
}

h2 {
  font-size: 34px;

  @media (max-width: 660px) {
    font-size: 24px;
  }
}

h3 {
  font-size: 28px;

  @media (max-width: 660px) {
    font-size: 22px;
  }
}

h4 {
  font-size: 24px;

  @media (max-width: 660px) {
    font-size: 22px;
  }
}

h5 {
  font-size: 22px;

  @media (max-width: 660px) {
    font-size: 20px;
  }
}

h6 {
  font-size: 18px;
}

p {
  margin-bottom: .75em;
}

b, strong {
  font-weight: 900;
}

cite, dfn, em, i {
  font-style: italic
}

address {
  margin: 0 0 1.5em
}


