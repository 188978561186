.questions {
  display: flex;
  flex-direction: column;

  .row {
    margin-top: 15px;
  }
}

.question-item {
  display: flex;
  flex-direction: row;

  p {
    vertical-align: middle;

    font-family: $font-nunito;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.18;
    letter-spacing: 0.1px;
    margin-left: 10px;
  }
}